import React, { useEffect } from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'
import './nos-prestations.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { motion, useAnimation } from "framer-motion";
import { useInView } from 'react-intersection-observer';
import { faClipboardCheck, faToolbox, faTruckMonster, faWrench } from '@fortawesome/free-solid-svg-icons'

export default function NosPrestations() {

    const SectionEntretien = () => {

        const controls = useAnimation();
        const { ref, inView } = useInView({});

        useEffect(() => {
            if (inView) {
                controls.start('visible');
            }
            if (!inView) {
                controls.start('hidden');
            }
        }, [controls, inView]);

        const toTop = {
            hidden: { opacity: 0, y: -40 },
            visible: { opacity: 1, y: 0 },
        }

        return (
            <section className="section" ref={ref}>
                <div className="flex_wrap_center container">
                    <div className="width50 trigger-element">
                        <motion.h2
                            className="after_title"
                            initial="hidden"
                            animate={controls}
                            variants={toTop}
                            transition={{ ease: "easeOut", duration: .5, delay: 0.2 }}
                        >
                            Entretien et vidange
                        </motion.h2>
                        <motion.p
                            className="text"
                            initial="hidden"
                            animate={controls}
                            variants={toTop}
                            transition={{ ease: "easeOut", duration: .5, delay: .4 }}
                        >
                            Il est important de faire une révision complète et la vidange de sa voiture avant un long trajet. Si vous partez en vacances, prenez rendez-vous afin de vous en occuper.
                        </motion.p>
                    </div>
                    <div className="width50 flex_wrap_center_center">
                        <motion.div
                            initial="hidden"
                            animate={controls}
                            variants={toTop}
                            transition={{ ease: "easeOut", duration: .5, delay: .7 }}
                        >
                            <FontAwesomeIcon icon={faToolbox} />
                        </motion.div>
                    </div>
                </div>
            </section>
        )
    }

    const SectionCourroie = () => {

        const controls = useAnimation();
        const { ref, inView } = useInView({});

        useEffect(() => {
            if (inView) {
                controls.start('visible');
            }
            if (!inView) {
                controls.start('hidden');
            }
        }, [controls, inView]);

        const toTop = {
            hidden: { opacity: 0, y: -40 },
            visible: { opacity: 1, y: 0 },
        }

        return (
            <section className="section" ref={ref}>
                <div className="flex_wrap_center container">
                    <div className="width50 flex_wrap_center_center">
                        <motion.div
                            initial="hidden"
                            animate={controls}
                            variants={toTop}
                            transition={{ ease: "easeOut", duration: .5, delay: .7 }}
                        >
                            <FontAwesomeIcon icon={faWrench} />
                        </motion.div>
                    </div>
                    <div className="width50 trigger-element">
                        <motion.h2
                            className="after_title"
                            initial="hidden"
                            animate={controls}
                            variants={toTop}
                            transition={{ ease: "easeOut", duration: .5, delay: 0.2 }}
                        >
                            Courroie de distribution
                        </motion.h2>
                        <motion.p
                            className="text"
                            initial="hidden"
                            animate={controls}
                            variants={toTop}
                            transition={{ ease: "easeOut", duration: .5, delay: .4 }}
                        >
                            La courroie de distribution est essentielle pour le moteur. Protégez le !
                        </motion.p>
                    </div>
                </div>
            </section>
        )
    }

    const SectionPneus = () => {

        const controls = useAnimation();
        const { ref, inView } = useInView({});

        useEffect(() => {
            if (inView) {
                controls.start('visible');
            }
            if (!inView) {
                controls.start('hidden');
            }
        }, [controls, inView]);

        const toTop = {
            hidden: { opacity: 0, y: -40 },
            visible: { opacity: 1, y: 0 },
        }

        return (
            <section className="section" ref={ref}>
                <div className="flex_wrap_center container">
                    <div className="width50 flex_wrap_center_center">
                        <motion.div
                            initial="hidden"
                            animate={controls}
                            variants={toTop}
                            transition={{ ease: "easeOut", duration: .5, delay: .7 }}
                        >
                            <FontAwesomeIcon icon={faTruckMonster} />
                        </motion.div>
                    </div>
                    <div className="width50 trigger-element">
                        <motion.h2
                            className="after_title"
                            initial="hidden"
                            animate={controls}
                            variants={toTop}
                            transition={{ ease: "easeOut", duration: .5, delay: 0.2 }}
                        >
                            Montage de pneus
                        </motion.h2>
                        <motion.p
                            className="text"
                            initial="hidden"
                            animate={controls}
                            variants={toTop}
                            transition={{ ease: "easeOut", duration: .5, delay: .4 }}
                        >
                            Vous n'avez pas forcément acheté vos pneus chez nous, nous vous proposons un forfait montage équilibrage remplacement valve pour tous les pneus été, hiver.<br />
                            Montage et démontage roues d'hiver pour rouler en toute sécurité.
                        </motion.p>
                    </div>
                </div>
            </section>
        )
    }

    const SectionCt = () => {

        const controls = useAnimation();
        const { ref, inView } = useInView({});

        useEffect(() => {
            if (inView) {
                controls.start('visible');
            }
            if (!inView) {
                controls.start('hidden');
            }
        }, [controls, inView]);

        const toTop = {
            hidden: { opacity: 0, y: -40 },
            visible: { opacity: 1, y: 0 },
        }

        return (
            <section className="section" ref={ref}>
                <div className="flex_wrap_center container">
                    <div className="width50 trigger-element">
                        <motion.h2
                            className="after_title"
                            initial="hidden"
                            animate={controls}
                            variants={toTop}
                            transition={{ ease: "easeOut", duration: .5, delay: 0.2 }}
                        >
                            Diagnostic CT
                        </motion.h2>
                        <motion.p
                            className="text"
                            initial="hidden"
                            animate={controls}
                            variants={toTop}
                            transition={{ ease: "easeOut", duration: .5, delay: .4 }}
                        >
                            Avant votre passage obligatoire au contrôle technique, nous vous proposons un forfait précontrôle qui permettra de détecter les points éventuels de défaillance de votre véhicule, les besoins de réparation indispensable avant le passage pour vous permettre d'anticiper (freins, échappement, réseau électrique et électronique, usure des pneus...). Nous vous fournirons un rapport précis et détaillé.
                        </motion.p>
                    </div>
                    <div className="width50 flex_wrap_center_center">
                        <motion.div
                            initial="hidden"
                            animate={controls}
                            variants={toTop}
                            transition={{ ease: "easeOut", duration: .5, delay: .7 }}
                        >
                            <FontAwesomeIcon icon={faClipboardCheck} />
                        </motion.div>
                    </div>
                </div>
            </section>
        )
    }

    const SectionCarrosseriePeinture = () => {

        const controls = useAnimation();
        const { ref, inView } = useInView({});

        useEffect(() => {
            if (inView) {
                controls.start('visible');
            }
            if (!inView) {
                controls.start('hidden');
            }
        }, [controls, inView]);

        const toTop = {
            hidden: { opacity: 0, y: -40 },
            visible: { opacity: 1, y: 0 },
        }

        return (
            <section className="section" ref={ref}>
                <div className="flex_wrap_center container">
                    <div className="width50 trigger-element">
                        <motion.h2
                            className="after_title"
                            initial="hidden"
                            animate={controls}
                            variants={toTop}
                            transition={{ ease: "easeOut", duration: .5, delay: 0.2 }}
                        >
                            Carrosserie et peinture
                        </motion.h2>
                        <motion.p
                            className="text"
                            initial="hidden"
                            animate={controls}
                            variants={toTop}
                            transition={{ ease: "easeOut", duration: .5, delay: .4 }}
                        >
                            Les petits incidents (éraflures, pare-choc, portières rayées) peuvent se répéter, il est important de remettre votre véhicule en état.<br />
                            Nous vous proposons un service de carrosserie de proximité avec un personnel compétent et des procédés de peinture de qualité qui respecte l'environnement.<br />
                            Nous réparons les véhicules toutes marques et toutes assurances.
                        </motion.p>
                    </div>
                    <div className="width50 flex_wrap_center_center">
                        <motion.div
                            initial="hidden"
                            animate={controls}
                            variants={toTop}
                            transition={{ ease: "easeOut", duration: .5, delay: .7 }}
                        >
                            <FontAwesomeIcon icon={faClipboardCheck} />
                        </motion.div>
                    </div>
                </div>
            </section>
        )
    }

    return (
        <Layout>
            <Seo title="Nos prestations" />
            <div id="prestations">

                <SectionEntretien />

                <SectionCourroie />

                <SectionCt />

                <SectionPneus />

                <SectionCarrosseriePeinture />

            </div>
        </Layout>
    )
}